import {useRouter} from 'next/router';
import {useContext, useState} from 'react';
import url from 'url';
import TertiaryCta from '../TertiaryCta';
import {ctaCopy, ctaPaths} from './cta-data';
import facilityInfo from '../../../lib/facility-info';
import AppContext from '../../AppContext';
import {BREAKPOINT} from 'aac-components/utils/styles';
import Link from 'next/link';
import GoogleMap from 'aac-components/components/GoogleMap/GoogleMap';
import styled from 'styled-components';
import Image from 'next/image';
import OutsideClick from 'aac-components/components/OutsideClick';
import IconChevron from 'aac-components/components/Icons/Arrow/IconChevron';
import {isMappableArray} from 'aac-components/utils/helpers';
import Button from 'aac-components/components/Button';

const sections = [
    {id: 'highlights', name: 'Facility Highlights'},
    {id: 'services'},
    {id: 'payment'},
    {id: 'location'},
    {id: 'statistics'},
];

const FacilitySpotlightCta = ({facility = ''}) => {
    const {asPath} = useRouter();
    const {pathname} = url.parse(asPath);
    const {isMobile} = useContext(AppContext);

    const pageMatches =
        ctaPaths.find((item) => `/treatment-centers${item?.pathname}` === pathname)
            ?.facility || [];

    const facilities = Object.entries(ctaCopy).reduce((acc, curr) => {
        const currentFacility = curr[0];
        const ctaInfo = {
            name: facilityInfo[currentFacility]?.name,
            short_name: facilityInfo[currentFacility]?.short_name,
            img: facilityInfo[currentFacility]?.img,
            ...curr[1],
        };
        // either the facility is passed as prop OR there's no prop facility and there is a page match
        if (
            (facility && facility === curr[1]?.id) ||
            (!facility && pageMatches?.includes(currentFacility))
        ) {
            acc.push({...ctaInfo});
        }
        return acc;
    }, []);

    const [activeFacility, setActiveFacility] = useState(0);
    const [activeTab, setActiveTab] = useState('highlights');
    const [isMobileDropdownOpen, setIsMobileDropdownOpen] = useState(false);

    // if there's no facility accodicated with this page in the ctaPaths array, just show the default Tertiary CTA
    if (!isMappableArray(facilities)) {
        return <TertiaryCta />;
    }

    const getActiveTabName = () => {
        const active = sections.find((section) => section?.id === activeTab);
        return active?.name || active?.id;
    };

    return (
        <>
            <div className="facility-spotlight">
                <div className="container">
                    <div>
                        <ul className="facility__titles">
                            {facilities?.length >= 2 ? (
                                facilities.map((facility, index) => {
                                    return (
                                        <li
                                            key={facility?.slug}
                                            onClick={() => setActiveFacility(index)}
                                            className={`facility__title ${
                                                index === activeFacility ? 'active' : ''
                                            }`}>
                                            {facility.name}
                                        </li>
                                    );
                                })
                            ) : (
                                <li className="facility__title solo">
                                    {facilities[0]?.name}
                                </li>
                            )}
                        </ul>
                        {isMobile && (
                            <div className="sections__dropdown">
                                <OutsideClick
                                    onClick={() => setIsMobileDropdownOpen(false)}>
                                    <div
                                        className="sections__dropdown--active"
                                        onClick={() =>
                                            setIsMobileDropdownOpen(
                                                (prevInput) => !prevInput,
                                            )
                                        }>
                                        <span> {getActiveTabName()}</span>
                                        <IconChevron
                                            fill="var(--interactive"
                                            rotate={isMobileDropdownOpen ? '180' : '0'}
                                        />
                                    </div>
                                    <ul>
                                        {sections.map((section) => {
                                            return (
                                                <li
                                                    key={section?.id}
                                                    className={
                                                        activeTab === section?.id
                                                            ? 'active'
                                                            : ''
                                                    }
                                                    onClick={() => {
                                                        setActiveTab(section?.id);
                                                        setIsMobileDropdownOpen(false);
                                                    }}>
                                                    {section?.name || section?.id}
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </OutsideClick>
                            </div>
                        )}
                        {!isMobile && (
                            <>
                                <ul className="sections__list">
                                    {sections.map((section) => {
                                        return (
                                            <li
                                                key={section?.id}
                                                className={
                                                    activeTab === section?.id
                                                        ? 'active'
                                                        : ''
                                                }
                                                onClick={() => setActiveTab(section?.id)}>
                                                {section?.name || section?.id}
                                            </li>
                                        );
                                    })}
                                </ul>
                                <Link
                                    href="/treatment-centers"
                                    style={{
                                        textAlign: 'left',
                                        margin: '24px 0 0 0',
                                        display: 'block',
                                    }}
                                    title="Explore Our Nationwide Rehab Centers">
                                    Explore Our Nationwide Rehab Centers
                                </Link>
                            </>
                        )}
                    </div>
                    <div className="facility__content">
                        {activeTab === 'highlights' && (
                            <>
                                {facilities[activeFacility]?.img && (
                                    <div className="facility__img">
                                        <Image
                                            src={facilities[activeFacility]?.img}
                                            fill
                                            alt={facilities[0]?.name}
                                            style={{objectFit: 'cover'}}
                                            sizes="500px"
                                        />
                                    </div>
                                )}
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: `${facilities[activeFacility]?.[activeTab]}`,
                                    }}
                                />
                                <div className="facility__buttons">
                                    <Button
                                        style={{width: '100%'}}
                                        href={`/treatment-centers/${facilities[activeFacility]?.slug}`}
                                        title={`View Facility Profile`}>
                                        View Facility Profile
                                    </Button>
                                    <Button
                                        theme="invert"
                                        style={{width: '100%'}}
                                        href={`/treatment-centers/${facilities[activeFacility]?.slug}/facility-tour`}
                                        title="Take a Tour">
                                        Take a Tour
                                    </Button>
                                </div>
                                {!isMobile && (
                                    <Link
                                        href="/treatment-centers"
                                        style={{
                                            textAlign: 'center',
                                            margin: '24px 0 0 0',
                                            display: 'block',
                                        }}
                                        title="Explore Our Nationwide Rehab Centers">
                                        Explore Our Nationwide Rehab Centers
                                    </Link>
                                )}
                            </>
                        )}
                        {activeTab === 'services' && (
                            <>
                                <div className="text-xl bold">
                                    Services offered include:
                                </div>
                                <ul>
                                    {facilities[activeFacility]?.services.map(
                                        (service) => {
                                            return (
                                                <li
                                                    dangerouslySetInnerHTML={{
                                                        __html: service,
                                                    }}
                                                />
                                            );
                                        },
                                    )}
                                    <li>
                                        {
                                            <Link
                                                href={`/treatment-centers/${facilities[activeFacility]?.slug}`}
                                                legacyBehavior>
                                                <a title="See All Services Here">
                                                    See All Services Here
                                                </a>
                                            </Link>
                                        }
                                    </li>
                                </ul>
                            </>
                        )}
                        {activeTab === 'payment' && (
                            <>
                                <div className="text-xl bold">Cost & Insurance</div>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: `${facilities[activeFacility]?.[activeTab]}`,
                                    }}
                                />
                                <div className="facility__link">
                                    <Link
                                        href="/verify-insurance"
                                        title="Verify Your Insurance Coverage for Treatment">
                                        Verify Your Insurance Coverage for Treatment
                                    </Link>
                                </div>
                            </>
                        )}
                        {activeTab === 'location' && (
                            <>
                                <div className="text-xl bold">Location:</div>

                                <GoogleMapStyles>
                                    <GoogleMap
                                        address={`${facilities[activeFacility]?.name}, ${
                                            facilities[activeFacility]?.address ||
                                            facilities[activeFacility]?.location
                                        }`}
                                        zoom={10}
                                    />
                                </GoogleMapStyles>
                            </>
                        )}
                        {activeTab === 'statistics' && (
                            <>
                                <div className="text-xl bold">Statistics</div>
                                <ul>
                                    {facilities[activeFacility]?.statistics.map(
                                        (service) => {
                                            return (
                                                <li
                                                    dangerouslySetInnerHTML={{
                                                        __html: service,
                                                    }}
                                                />
                                            );
                                        },
                                    )}
                                </ul>
                            </>
                        )}
                        {isMobile && (
                            <Link
                                href="/treatment-centers"
                                style={{
                                    textAlign: 'center',
                                    margin: '24px 0 0 0',
                                    display: 'block',
                                }}
                                title="Explore Our Nationwide Rehab Centers">
                                Explore Our Nationwide Rehab Centers
                            </Link>
                        )}
                    </div>
                </div>
                <div className="facility-spotlight__label">Closest to You</div>
            </div>
            <style jsx>
                {`
                    .facility-spotlight {
                        background: var(--gray-100);
                        border: 3px solid var(--warning);
                        padding: 30px 16px;
                        border-radius: 8px;
                        position: relative;
                    }
                    .facility-spotlight__label {
                        background: rgba(255, 173, 13, 0.8);
                        border: 2px solid var(--warning);
                        border-left: 0;
                        border-top: 0;
                        font-size: 12px;
                        padding: 2px 8px;
                        max-width: fit-content;
                        border-radius: 0 0 4px 0;
                        position: absolute;
                        top: 0;
                        left: 0;
                        font-weight: bold;
                    }
                    .container {
                        display: grid;
                        grid-template-columns: 1fr;
                        grid-gap: 30px;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .container {
                            grid-template-columns: 1fr 2fr;
                            grid-gap: 60px;
                        }
                    }
                    li {
                        margin-bottom: 16px;
                    }
                    .facility__img {
                        width: 100%;
                        height: 200px;
                        position: relative;
                        border-radius: 8px;
                        background: var(--gray-300);
                        overflow: hidden;
                        margin: 8px 0 0 0;
                    }
                    .facility__titles {
                        list-style: none;
                        padding: 0;
                        margin: 0 0 30px 0;
                    }
                    .facility__title {
                        color: var(--gray-400);
                        border-left: 3px solid var(--interactive-200);
                        padding-left: 16px;
                        cursor: pointer;
                        margin: 8px 0;
                        max-width: fit-content;
                        font-size: 24px;
                    }
                    .facility__title.active {
                        font-weight: bold;
                        color: #000;
                        border-left: 3px solid var(--interactive);
                    }
                    .facility__title:hover {
                        color: #000;
                        transition: all 0.25s;
                    }

                    .facility__title.solo {
                        border: none;
                        font-weight: bold;
                        color: #000;
                        padding: 0;
                    }
                    .sections__dropdown {
                        position: relative;
                    }
                    .sections__dropdown--active {
                        background: var(--gray-200);
                        border: 1px solid var(--gray-300);
                        padding: 16px;
                        border-radius: ${isMobileDropdownOpen ? '8px 8px 0 0' : '8px'};
                        color: var(--interactive-300);
                        font-weight: bold;
                        text-transform: capitalize;
                        display: flex;
                        justify-content: space-between;
                    }
                    .sections__dropdown ul {
                        border: 1px solid var(--gray-200);

                        background: #fff;
                        padding: 0;
                        margin: 0;
                        list-style: none;
                        border-radius: 0 0 8px 8px;
                        position: absolute;
                        width: 100%;
                        z-index: 1;
                        display: ${isMobileDropdownOpen ? 'block' : 'none'};
                    }
                    .sections__dropdown ul li {
                        padding: 8px 16px;
                        margin: 0;
                        text-transform: capitalize;
                    }
                    .sections__list {
                        list-style: none;
                        margin: 0;
                        padding: 0;
                    }
                    .sections__list li {
                        text-transform: capitalize;
                        border-left: 3px solid var(--gray-300);
                        padding: 12px 0 12px 16px;
                        margin-bottom: 0;
                        color: var(--gray-400);
                        max-width: fit-content;
                        cursor: pointer;
                    }
                    .sections__list li:hover {
                        color: #000;
                    }
                    .sections__list li.active {
                        color: #000;
                        border-left: 3px solid var(--interactive-300);
                    }
                    .facility__link :global(a) {
                        font-size: 16px;
                        font-weight: bold;
                        text-decoration: none;
                        padding-bottom: 4px;
                        display: block;
                        max-width: fit-content;
                        margin: 0 0 24px 0;
                        border-bottom: 3px solid var(--interactive-200);
                    }
                    .facility__buttons {
                        display: grid;
                        grid-template-columns: 1fr;
                        grid-gap: 16px;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .facility__buttons {
                            grid-template-columns: 1fr 1fr;
                        }
                    }
                `}
            </style>
        </>
    );
};
export default FacilitySpotlightCta;

const GoogleMapStyles = styled.div`
    .google-map {
        max-width: 100%;
        border-radius: 8px;
        margin: 24px 0;
        height: 200px;
        border: 1px solid var(--gray-300);
    }
    iframe {
        border-radius: 8px;
        width: 100%;
        position: relative;
        height: 200px;
        max-height: 200px;
    }
    img {
        border-radius: 8px;
    }
    @media screen and (min-width: ${BREAKPOINT}) {
        .google-map,
        iframe {
            height: 400px;
            max-height: 400px;
        }
    }
`;
