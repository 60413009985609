import {TableOfContents} from 'aac-components/components/TableOfContents';
import {useContext} from 'react';
import styled from 'styled-components';
import AppContext from '../AppContext';

const TableOfContentsStyles = styled.div`
    .toc__header {
        background: var(--gray-200) !important;
        border: 1px solid var(--gray-200) !important;
    }
    .toc__dropdown {
        border: 1px solid var(--gray-200) !important;
    }
`;

const TableOfContentsModified = () => {
    const {isMobile} = useContext(AppContext);

    const scrollToH2 = (ref) => {
        if (typeof window !== 'undefined' && document) {
            const el = ref?.current || ref;
            const headerHeight =
                typeof window !== 'undefined'
                    ? document?.querySelector('header')?.offsetHeight || 0
                    : 0;
            const offset = el.getBoundingClientRect().top + window.scrollY - headerHeight;

            const extraPadding =
                document.getElementById('nav-container').offsetHeight +
                (isMobile ? 50 : 100);

            el &&
                window.scrollTo({
                    top: offset - extraPadding,
                    behavior: 'smooth',
                });
        }
    };

    return (
        <TableOfContentsStyles>
            <TableOfContents title="Jump to Section" scrollTo={scrollToH2} />
        </TableOfContentsStyles>
    );
};
export default TableOfContentsModified;
